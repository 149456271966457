/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('bootstrap/dist/css/bootstrap.min.css')
require("prismjs/themes/prism-okaidia.css")

require('./src/assets/fonts/icomoon.css')
require('./src/assets/css/resume-theme.less')
require('./src/assets/css/index.less')
